import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
	Alert,
	AppBar,
	Breadcrumbs,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Fab,
	Snackbar,
	TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/ContentCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SendIcon from "@mui/icons-material/Send";
import RighArrowIcon from "@mui/icons-material/ArrowRightAlt";
import EditIcon from "@mui/icons-material/Edit";
import Drawer from "@mui/material/Drawer";
import {
	MenuButtonBold,
	MenuButtonItalic,
	MenuControlsContainer,
	MenuDivider,
	MenuSelectHeading,
	RichTextEditor,
	HeadingWithAnchor,
	LinkBubbleMenu,
	LinkBubbleMenuHandler,
	MenuButtonAddTable,
	MenuButtonBlockquote,
	MenuButtonBulletedList,
	MenuButtonCode,
	MenuButtonCodeBlock,
	MenuButtonEditLink,
	MenuButtonOrderedList,
	MenuButtonRemoveFormatting,
	MenuButtonStrikethrough,
	MenuButtonSubscript,
	MenuButtonSuperscript,
	MenuButtonTaskList,
	ResizableImage,
	TableBubbleMenu,
	TableImproved,
} from "mui-tiptap";
import StarterKit from "@tiptap/starter-kit";
import Toolbar from "@mui/material/Toolbar";
import { richTextFromMarkdown } from "@contentful/rich-text-from-markdown";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import { MuiMarkdown, getOverrides } from "mui-markdown";
import { Barra } from "../components/Barra";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";

import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Code } from "@tiptap/extension-code";
import { CodeBlock } from "@tiptap/extension-code-block";
import { Document } from "@tiptap/extension-document";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import { Gapcursor } from "@tiptap/extension-gapcursor";
import { HardBreak } from "@tiptap/extension-hard-break";
import { History } from "@tiptap/extension-history";
import { Italic } from "@tiptap/extension-italic";
import { Link as LinkExt } from "@tiptap/extension-link";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Strike } from "@tiptap/extension-strike";
import { Subscript } from "@tiptap/extension-subscript";
import { Superscript } from "@tiptap/extension-superscript";
import { TableCell } from "@tiptap/extension-table-cell";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableRow } from "@tiptap/extension-table-row";
import { TaskItem } from "@tiptap/extension-task-item";
import { TaskList } from "@tiptap/extension-task-list";
import { Text } from "@tiptap/extension-text";
import SaveIcon from "@mui/icons-material/Save";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import "../App.css";

function handleClick(event) {
	event.preventDefault();
	console.info("You clicked a breadcrumb.");
}

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const CustomLinkExtension = LinkExt.extend({
	inclusive: false,
});

const CustomSubscript = Subscript.extend({
	excludes: "superscript",
});

const CustomSuperscript = Superscript.extend({
	excludes: "subscript",
});
export const Chat = (props) => {
	const { window } = props;
	const rteRef = React.useRef(null);
	const editor = rteRef.current?.editor;
	const params = useLocation();
	const { user } = React.useContext(AuthContext);
	const [ejecuciones, setEjecuciones] = React.useState([]);
	const [mensajesToSend, setMensajesToSend] = React.useState([]);
	const [mensajes, setMensajes] = React.useState([]);
	const [firstMensajes, setFirstMensajes] = React.useState([]);
	const [lastMensaje, setLastMensaje] = React.useState("");
	const [preguntaAbierta, setPreguntaAbierta] = React.useState("");
	const [solicitaCambios, setSolicitaCambios] = React.useState("");
	const [title, setTitle] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [bitacoraId, setBitacoraId] = React.useState(0);

	const [open, setOpen] = React.useState(false);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [mensajeAlerta, setMensajeAlerta] = React.useState('');
	const [openTitle, setOpenTitle] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	
	const handleClickAlert = (msg) => {
		setOpenAlert(true);
		setMensajeAlerta(msg);
	};

	const handleCloseAlert = () => {
		setOpenAlert(false);
	};

	const handleClickOpenTitle = () => {
		setOpenTitle(true);
	};

	const handleCloseTitle = () => {
		setOpenTitle(false);
	};

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	function exportHTML() {
		var preHtml =
			"<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
		var postHtml = "</body></html>";
		var html = preHtml + rteRef.current?.editor?.getHTML() + postHtml;

		var blob = new Blob(["\ufeff", html], {
			type: "application/msword",
		});

		// Specify link url
		var url =
			"data:application/vnd.ms-word;charset=utf-8," +
			encodeURIComponent(html);

		// Specify file name
		var filename = "document.doc";

		// Create download link element
		var downloadLink = document.createElement("a");

		document.body.appendChild(downloadLink);

		if (navigator.msSaveOrOpenBlob) {
			navigator.msSaveOrOpenBlob(blob, filename);
		} else {
			// Create a link to the file
			downloadLink.href = url;

			// Setting the file name
			downloadLink.download = filename;

			//triggering the function
			downloadLink.click();
		}

		document.body.removeChild(downloadLink);
	}

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
			sx={{ fontSize: 11 }}
		>
			Inicio
		</Link>,
		<Typography key="2" color="inherit" sx={{ fontSize: 11 }}>
			{params.state.asignaturaSelected.label}
		</Typography>,
		<Typography key="3" color="inherit" sx={{ fontSize: 11 }}>
			{params.state.unidadeSelected.label}
		</Typography>,
		<Typography key="4" color="text.primary" sx={{ fontSize: 11 }}>
			{params.state.aprendizajeSelected.label}
		</Typography>,
	];

	const getPrompt = async (asignatura_id, unidad_id, aprendizaje_id) => {
		try {
			let data = JSON.stringify({
				asignatura_id: asignatura_id,
				unidad_id: unidad_id,
				aprendizaje_id: aprendizaje_id,
			});

			let config = {
				method: "post",
				url: `${process.env.REACT_APP_URL_APP}prompt`,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + user.authorization.token,
				},
				data: data,
			};

			const response = await axios.request(config);

			return response.data.prompt;
		} catch (error) {
			console.log(error);
		}
	};

	const getEjecuciones = async (a) => {
		try {
			let config = {
				method: "get",
				url: `${process.env.REACT_APP_URL_APP}ejecucion`,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + user.authorization.token,
				},
			};

			const response = await axios.request(config);
			setEjecuciones(response.data.ejecuciones);
			// console.log(response.data.ejecuciones);
		} catch (error) {
			console.log(error);
		}
	};

	const sendBitacora = async (pregunta, respuesta, ejecucion_id) => {
		let data = new FormData();
		data.append("usuario", user.user);
		data.append("starttime", new Date());
		data.append("endtime", new Date());
		data.append("pregunta", pregunta);
		data.append("respuesta", respuesta);
		data.append("aprendizaje_id", params.state.aprendizajeSelected.id);
		data.append("ejecucion_id", ejecucion_id);

		let config = {
			method: "post",
			url: `${process.env.REACT_APP_URL_APP}bitacora`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + user.authorization.token,
			},
			data: data,
		};

		const response = await axios.request(config);
		console.log(response.data.bitacora_id);
		setBitacoraId(response.data.bitacora_id);
	};

	const sendHistorial = async (tipo, mnsjes) => {
		let data = new FormData();
		data.append("bitacora_id", bitacoraId);
		data.append("title", tipo === "Guardado" ? title : "");
		data.append("respuesta", editor.getHTML());
		data.append("pregunta", tipo === "Mejora" ? solicitaCambios : "");
		data.append("tipo", tipo);
		data.append("mensajes", mnsjes);

		let config = {
			method: "post",
			url: `${process.env.REACT_APP_URL_APP}historial`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + user.authorization.token,
			},
			data: data,
		};

		const response = await axios.request(config);
		console.log(response.data);
		handleClickAlert("Edición guardada con éxito!");
	};

	const getFirstMessage = async (prompt) => {
		try {
			let data = new FormData();
			data.append("prompt", prompt);

			let config = {
				method: "post",
				url: `${process.env.REACT_APP_URL_APP}firstmessage`,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + user.authorization.token,
				},
				data: data,
			};

			const response = await axios.request(config);
			console.log("Mnesajes", response.data);
			setMensajesToSend(response.data);
			setMensajes(response.data);
			setFirstMensajes(response.data);
		} catch (error) {
			console.log(error);
		}
	};

	React.useEffect(() => {
		const main = async () => {
			try {
				const prompt = await getPrompt(
					params.state.asignaturaSelected.id,
					params.state.unidadeSelected.id,
					params.state.aprendizajeSelected.id
				);
				await getFirstMessage(prompt);
				await getEjecuciones();
			} catch (err) {
				console.log(err);
			}
		};
		main();
	}, []);

	const getMessage = async (ejecucion_id) => {
		setLastMensaje("");
		setLoading(true);

		let newMensajes = [];
		if (ejecucion_id === 1000) {
			newMensajes = [...mensajes];
		} else {
			newMensajes = [...firstMensajes];
		}
		try {
			let newMensajesToSend = [...mensajesToSend];

			newMensajesToSend.push({
				role: "user",
				content:
					preguntaAbierta +
					", tu respuesta debe ser en html, usa almenos 2 espacios entre los titulos y parrafos y evita ```html ",
			});

			newMensajes.push({
				role: "user",
				content:
					ejecucion_id === 1000
						? solicitaCambios +
						  ", tu respuesta debe ser en html, usa almenos 2 espacios entre los titulos y parrafos y evita ```html"
						: preguntaAbierta,
			});

			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append(
				"Authorization",
				"Bearer " + user.authorization.token
			);

			var raw = JSON.stringify({
				mensajes:
					ejecucion_id == 0
						? newMensajesToSend
						: ejecucion_id === 1000
						? newMensajes
						: mensajesToSend,
				ejecucion_id:
					ejecucion_id == 0 && ejecucion_id == 1000
						? 0
						: ejecucion_id,
			});

			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: raw,
			};

			const response = await fetch(
				`${process.env.REACT_APP_URL_APP}message`,
				requestOptions
			);

			const reader = response.body.getReader();
			const decoder = new TextDecoder();

			let contenido = "";

			while (true) {
				const { value, done } = await reader.read();
				if (done) {
					if (ejecucion_id == 1000) {
						sendHistorial("Mejora", JSON.stringify(newMensajes));
					} else {
						sendBitacora(
							ejecucion_id == 0
								? JSON.stringify(newMensajesToSend)
								: JSON.stringify(mensajesToSend),
							contenido,
							ejecucion_id
						);
					}

					break;
				}

				const decodedChunk = decoder.decode(value, { stream: true });
				contenido += decodedChunk;
				setLastMensaje(contenido);
				const currentSelection = editor.state.selection;
				editor
					.chain()
					.setContent(contenido)
					.setTextSelection(currentSelection)
					.run();
			}

			newMensajes.push({
				role: "assistant",
				content: contenido,
			});

			setMensajes(newMensajes);

			setLoading(false);
			handleClickAlert("Edición con IA guardada con éxito!");
		} catch (error) {
			console.log("error");
		}
	};

	const editPrompt = (prompt) => {
		setPreguntaAbierta(prompt);
	};

	const container =
		window !== undefined ? () => window().document.body : undefined;
	return (
		<Box sx={{ display: "flex" }}>
			<Barra
				handleDrawerToggle={handleDrawerToggle}
				isOpen={mobileOpen}
			/>

			<Box sx={{ display: "flex" }}>
				<Box
					component="nav"
					sx={{ width: { sm: 400 }, flexShrink: { sm: 0 } }}
				>
					<Drawer
						container={container}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						sx={{
							display: { xs: "block", sm: "none" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: 250,
							},
						}}
					>
						<div style={{ backgroundColor: "#EBF2F9" }}>
							<Toolbar />
							<Divider />
							<Box
								sx={{ flexWrap: "wrap" }}
								key={`ejecucion-${0}`}
							>
								<Card sx={{ margin: 1 }}>
									<CardContent sx={{ minHeight: 70 }}>
										<textarea
											name="textarea"
											rows="10"
											value={preguntaAbierta}
											placeholder="Pregunta abierta"
											onChange={(e) => {
												setPreguntaAbierta(
													e.target.value
												);
											}}
										></textarea>
									</CardContent>
									<CardActions disableSpacing>
										<Button
											size="small"
											fullWidth
											disabled={
												preguntaAbierta.length === 0
											}
											endIcon={<RighArrowIcon />}
											variant="outlined"
											sx={{ backgroundColor: "#D7E5F4" }}
											onClick={() => {
												getMessage(0);
											}}
										>
											Generar
										</Button>
									</CardActions>
								</Card>
							</Box>
							{ejecuciones.map((ejecucion, index) => (
								<Box
									sx={{ flexWrap: "wrap" }}
									key={`ejecucion-${index}`}
								>
									<Card sx={{ margin: 1 }}>
										<CardHeader
											sx={{
												position: "absolute",
												marginTop: "-15px",
												right: 0,
											}}
											action={
												<IconButton
													onClick={async () => {
														editPrompt(
															ejecucion.prompt
														);
													}}
												>
													<EditIcon />
												</IconButton>
											}
										></CardHeader>
										<CardContent sx={{ minHeight: 70 }}>
											<Typography
												variant="body2"
												color="#024588"
											>
												{ejecucion.name}
											</Typography>
										</CardContent>
										<CardActions disableSpacing>
											<Button
												size="small"
												fullWidth
												endIcon={<RighArrowIcon />}
												variant="outlined"
												sx={{
													backgroundColor: "#D7E5F4",
												}}
												onClick={async () => {
													getMessage(ejecucion.id);
												}}
											>
												Generar
											</Button>
										</CardActions>
									</Card>
								</Box>
							))}
						</div>
					</Drawer>
					<Drawer
						variant="permanent"
						sx={{
							display: { xs: "none", sm: "block" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: 400,
							},
						}}
						open
					>
						<div style={{ backgroundColor: "#EBF2F9" }}>
							<Toolbar />
							<Divider />
							<Box
								sx={{ flexWrap: "wrap" }}
								key={`ejecucion-${0}`}
							>
								<Card sx={{ margin: 1 }}>
									<CardContent sx={{ minHeight: 70 }}>
										<textarea
											name="textarea"
											rows="10"
											cols="35"
											placeholder="Pregunta abierta"
											value={preguntaAbierta}
											onChange={(e) => {
												setPreguntaAbierta(
													e.target.value
												);
											}}
										></textarea>
									</CardContent>
									<CardActions disableSpacing>
										<Button
											size="small"
											fullWidth
											endIcon={<RighArrowIcon />}
											variant="outlined"
											sx={{ backgroundColor: "#D7E5F4" }}
											onClick={() => {
												getMessage(0);
											}}
											disabled={loading}
										>
											Generar
										</Button>
									</CardActions>
								</Card>
							</Box>
							{ejecuciones.map((ejecucion, index) => (
								<Box
									sx={{ flexWrap: "wrap" }}
									key={`ejecucion-${index}`}
								>
									<Card sx={{ margin: 1 }}>
										<CardHeader
											sx={{
												position: "absolute",
												marginTop: "-15px",
												right: 0,
											}}
											action={
												<IconButton
													onClick={async () => {
														editPrompt(
															ejecucion.prompt
														);
													}}
												>
													<EditIcon />
												</IconButton>
											}
										></CardHeader>
										<CardContent sx={{ minHeight: 70 }}>
											<Typography
												variant="body2"
												color="#024588"
											>
												{ejecucion.name}
											</Typography>
										</CardContent>
										<CardActions disableSpacing>
											<Button
												size="small"
												fullWidth
												endIcon={<RighArrowIcon />}
												variant="outlined"
												sx={{
													backgroundColor: "#D7E5F4",
												}}
												onClick={async () => {
													getMessage(ejecucion.id);
												}}
												disabled={loading}
											>
												Generar
											</Button>
										</CardActions>
									</Card>
								</Box>
							))}
						</div>
					</Drawer>
				</Box>

				<Box
					component="main"
					sx={{ p: 2, width: { sm: `calc(100% - ${240}px)` } }}
				>
					<Toolbar />
					<Breadcrumbs
						separator="›"
						sx={{ pb: 2 }}
					>
						{breadcrumbs}
					</Breadcrumbs>

					<Box
						sx={{
							pr: 2,
							padding: 2,
							// height: "80vh",
							// overflowY: "auto",
							// borderColor: "#D7E5F4",
							// borderStyle: "solid",
							// borderRadius: 2,
							// overflowX: "hidden",
						}}
					>
						<div className="box-rt">
							<RichTextEditor
								ref={rteRef}
								extensions={[
									TableImproved.configure({
										resizable: true,
									}),
									TableRow,
									TableHeader,
									TableCell,

									BulletList,
									CodeBlock,
									Document,
									HardBreak,
									ListItem,
									OrderedList,
									Paragraph,
									CustomSubscript,
									CustomSuperscript,
									Text,

									// Blockquote must come after Bold, since we want the "Cmd+B" shortcut to
									// have lower precedence than the Blockquote "Cmd+Shift+B" shortcut. See
									// README
									Bold,
									Blockquote,

									Code,
									Italic,
									Strike,
									CustomLinkExtension.configure({
										autolink: true,
										linkOnPaste: true,
										openOnClick: false,
									}),
									LinkBubbleMenuHandler,

									// Extensions
									Gapcursor,
									HeadingWithAnchor.configure({
										// People shouldn't typically need more than 3 levels of headings, so
										// keep a more minimal set (than the default 6) to keep things simpler
										// and less chaotic.
										levels: [1, 2, 3],
									}),

									ResizableImage,

									// When images are dragged, we want to show the "drop cursor" for where they'll
									// land
									Dropcursor,

									TaskList,
									TaskItem.configure({
										nested: true,
									}),

									Placeholder.configure({
										placeholder:
											"Add your own content here...",
									}),

									// We use the regular `History` (undo/redo) extension when not using
									// collaborative editing
									History,
								]} // Or any Tiptap extensions you wish!
								content={lastMensaje} // Initial content for the editor
								// Optionally include `renderControls` for a menu-bar atop the editor:
								renderControls={() => (
									<MenuControlsContainer>
										<MenuSelectHeading />

										<MenuDivider />

										<MenuButtonBold />
										<MenuButtonItalic />
										<MenuButtonStrikethrough />
										<MenuButtonSubscript />
										<MenuButtonSuperscript />

										<MenuDivider />

										{/* <MenuButtonEditLink /> */}

										<MenuDivider />

										<MenuButtonOrderedList />
										<MenuButtonBulletedList />
										<MenuButtonTaskList />

										<MenuDivider />

										<MenuButtonBlockquote />

										<MenuDivider />

										<MenuButtonCode />

										<MenuButtonCodeBlock />

										<MenuDivider />

										<MenuButtonAddTable />

										<MenuDivider />

										<MenuButtonRemoveFormatting />
										<MenuDivider />
										<IconButton
											sx={{
												cursor: "pointer",
											}}
											disabled={loading}
											onClick={async () => {
												navigator.clipboard.writeText(
													lastMensaje.replace(
														/<[^>]+>/g,
														""
													)
												);
											}}
											title="Copiar HTML"
										>
											<ShareIcon />
										</IconButton>
										<IconButton
											disabled={loading}
											onClick={async () => {
												exportHTML();
											}}
											title="Descargar"
										>
											<FileDownloadIcon />
										</IconButton>
										<IconButton
											disabled={loading}
											onClick={handleClickOpen}
											color="primary"
											title="Mejor con IA"
										>
											<AutoFixHighIcon />
										</IconButton>
										<IconButton
											disabled={loading}
											onClick={async () => {
												handleClickOpenTitle();
											}}
											color="primary"
											title="Guardar"
										>
											<SaveIcon />
										</IconButton>
									</MenuControlsContainer>
								)}
							/>
						</div>
					</Box>
				</Box>
			</Box>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Solicitar cambios</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Solicita los cambios que necesitas en el contenido
						generado por el asistente.
					</DialogContentText>
					<TextField
						autoFocus
						required
						margin="dense"
						id="solicitaCambios"
						name="solicitaCambios"
						type="text"
						fullWidth
						variant="standard"
						onChange={(e) => {
							setSolicitaCambios(e.target.value);
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancelar</Button>
					<Button
						onClick={() => {
							getMessage(1000);
							handleClose();
						}}
					>
						Enviar
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={openTitle} onClose={handleCloseTitle}>
				<DialogTitle>Guardar cambios</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Guarda tu avance en la bitácora y continua trabajando
						despues.
					</DialogContentText>
					<TextField
						autoFocus
						required
						margin="dense"
						id="solicitaCambios"
						name="solicitaCambios"
						type="text"
						fullWidth
						variant="standard"
						onChange={(e) => {
							setTitle(e.target.value);
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseTitle}>Cancelar</Button>
					<Button
						onClick={() => {
							sendHistorial("Guardado", JSON.stringify(mensajes));
							handleCloseTitle();
						}}
					>
						Guardar
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				open={openAlert}
				autoHideDuration={5000}
				onClose={handleCloseAlert}
			>
				<Alert
					onClose={handleCloseAlert}
					severity="success"
					variant="filled"
					sx={{ width: "100%" }}
				>
					{mensajeAlerta}
				</Alert>
			</Snackbar>
		</Box>
	);
};
