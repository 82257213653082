import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowRightIcon from "@mui/icons-material/ArrowRightAlt";
import { Barra } from "../components/Barra";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Slide,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Home() {
  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();

  // const [text, setText] = useState("hola");
  const [asignaturas, setAsignaturas] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [aprendizajes, setAprendizajes] = useState([]);

  const [unidadesValue, setUnidadesValue] = useState(null);
  const [aprendizajesValue, setAprendizajesValue] = useState(null);

  const [asignaturaSelected, setAsignaturaSelected] = useState([]);
  const [unidadeSelected, setUnidadeSelected] = useState([]);
  const [aprendizajeSelected, setAprendizajeSelected] = useState([]);

  const [accepted, setAccepted] = useState([]);
  const [open, setOpen] = useState(false);
  const [sinCarga, setSinCarga] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getAsignaturas = async () => {
    // console.log(user.tipo);
    try {
      if (user.tipo === "Academico") {
        let formData = new FormData();
        formData.append("username", user.user);
  
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_URL_APP}asignaturas`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.authorization.token,
          },
          data: formData,
        };
  
        const response = await axios.request(config);
        // console.log(response.data.success);
  
        if (response.data.success == false) {
          setSinCarga(true);
        } else {
          const responseAsignaturas = response.data.asignaturas;
  
          const data = await responseAsignaturas.map((el) => {
            return { label: el.codigo + "-" + el.name_descripcion, id: el.id };
          });
  
          if (data.length == 0) {
            setSinCarga(true);
          }
  
          setAsignaturas(data);
        }
      } else {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_URL_APP}asignaturas`,
          headers: {
            Authorization: "Bearer " + user.authorization.token,
          },
        };
  
        const response = await axios.request(config);
  
        const responseAsignaturas = response.data.asignaturas;
  
        const data = await responseAsignaturas.map((el) => {
          return { label: el.codigo + "-" + el.name_descripcion + " - (" + el.asignaturaano + ")", id: el.id };
        });
  
        if (data.length == 0) {
          setSinCarga(true);
        }
  
        setAsignaturas(data);
      }
    } catch (error) {
      console.log(error);
    }
    

    // console.log(response);
  };

  const getDisclaimer = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL_APP}disclaimer/${user.user}`,
      headers: {
        Authorization: "Bearer " + user.authorization.token,
      },
    };

    const response = await axios.request(config);

    const responseDisclaimer = response.data;

    setAccepted(responseDisclaimer.accepted);

    if (!responseDisclaimer.accepted) {
      handleOpen();
    }
    // console.log(responseDisclaimer);
  };

  const getUnidadesByAsignaturas = async (asignatura) => {
    setAsignaturaSelected(asignatura);

    let data = JSON.stringify({
      asignatura_id: asignatura.id,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_APP}unidades`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.authorization.token,
      },
      data: data,
    };

    const response = await axios.request(config);
    // console.log(response.data);

    const responseUnidades = response.data.unidades;

    const responseFinal = await responseUnidades.map((el) => {
      return { label: el.name, id: el.id };
    });

    setUnidades(responseFinal);

    // console.log(responseFinal);
  };

  const getaprendizajesByIdUnidad = async (unidad) => {
    setUnidadeSelected(unidad);
    // console.log(unidad);
    let data = JSON.stringify({
      unidad_id: unidad.id,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_APP}aprendizajes`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.authorization.token,
      },
      data: data,
    };

    const response = await axios.request(config);
    // console.log(response.data);

    const responseAprendizajes = response.data.aprendizajes;

    const responseFinal = await responseAprendizajes.map((el) => {
      return { label: el.name, id: el.id };
    });

    setAprendizajes(responseFinal);

    // console.log(responseFinal);
  };

  useEffect(() => {
    getAsignaturas();
    // getDisclaimer();
  }, [""]);

  const continuar = () => {
    navigate("/chat", {
      state: {
        asignaturaSelected,
        unidadeSelected,
        aprendizajeSelected,
      },
    });
  };

  return (
    <div className="App">
      <main>
        {/* Hero unit */}
        <Barra />

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Use Google's location service?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Let Google help apps determine location. This means sending
              anonymous location data to Google, even when no apps are running.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleClose}>Agree</Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 20,
            pb: 2,
          }}
        >
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Asistente Instruccional
            </Typography>
            <Typography
              variant="span"
              align="justify"
              color="text.secondary"
              paragraph
            >
              Para comenzar, elija un módulo, a continuación, seleccione
              una unidad y, finalmente, escoja un tema de aprendizaje. Cada
              elección depende de la anterior. Una vez que haya seleccionado
              todas las opciones, haga clic en 'Continuar'.
            </Typography>
            {/* <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained">Main call to action</Button>
              <Button variant="outlined">Secondary action</Button>
            </Stack> */}
          </Container>
        </Box>
        <Container sx={{ py: 2 }} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <Autocomplete
                disablePortal
                loading={true}
                loadingText={`${
                  sinCarga ? "No tienes carga académica" : "Obteniendo datos..."
                } `}
                id="asignaturas"
                options={asignaturas}
                onChange={(event, elemento) => {
                  setUnidadeSelected([]);
                  setUnidadesValue(null);
                  setAprendizajesValue(null);
                  setUnidades([]);
                  setAprendizajes([]);
                  setAprendizajeSelected([]);
                  getUnidadesByAsignaturas(elemento);
                }}
                // value={asignaturaSelected}
                // sx={{ width: 300 }}
                // onChange={(event) => {console.log(event.currentTarget);}}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione un módulo" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Autocomplete
                disablePortal
                loading={true}
                loadingText="Seleccione un módulo..."
                id="unidades"
                options={unidades}
                value={unidadesValue}
                getOptionLabel={(option) => (option ? option.label : "")}
                onChange={(event, elemento) => {
                  getaprendizajesByIdUnidad(elemento);
                  setUnidadesValue(elemento);
                  setAprendizajesValue(null);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione unidad" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Autocomplete
                disablePortal
                id="aprendizajes"
                options={aprendizajes}
                loading={true}
                value={aprendizajesValue}
                getOptionLabel={(option) => (option ? option.label : "")}
                onChange={(event, elemento) => {
                  setAprendizajeSelected(elemento);
                  setAprendizajesValue(elemento);
                }}
                loadingText="Seleccione una unidad"
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione aprendizaje" />
                )}
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} sx={{ py: 4 }}>
            <Button
              variant="contained"
              onClick={continuar}
              disabled={!aprendizajesValue}
              endIcon={<ArrowRightIcon />}
              sx={{ background: "#022746" }}
            >
              Continuar
            </Button>
          </Stack>
        </Container>
      </main>
    </div>
  );
}

export default Home;
