import {
	Alert,
	AppBar,
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Slide,
	Snackbar,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Toolbar,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Barra } from "../components/Barra";
import { DataGrid, esES } from "@mui/x-data-grid";
import { AuthContext } from "../context/AuthContext";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import axios from "axios";
import moment from "moment";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import {
	AlignmentType,
	Document,
	Header,
	HeadingLevel,
	Packer,
	Paragrap,
	Paragraph,
	TextRun,
} from "docx";

import {
	MenuButtonBold,
	MenuButtonItalic,
	MenuControlsContainer,
	MenuDivider,
	MenuSelectHeading,
	RichTextEditor,
	HeadingWithAnchor,
	LinkBubbleMenu,
	LinkBubbleMenuHandler,
	MenuButtonAddTable,
	MenuButtonBlockquote,
	MenuButtonBulletedList,
	MenuButtonCode,
	MenuButtonCodeBlock,
	MenuButtonEditLink,
	MenuButtonOrderedList,
	MenuButtonRemoveFormatting,
	MenuButtonStrikethrough,
	MenuButtonSubscript,
	MenuButtonSuperscript,
	MenuButtonTaskList,
	ResizableImage,
	TableBubbleMenu,
	TableImproved,
} from "mui-tiptap";
import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Code } from "@tiptap/extension-code";
import { CodeBlock } from "@tiptap/extension-code-block";
import { Document as documentoExt } from "@tiptap/extension-document";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import { Gapcursor } from "@tiptap/extension-gapcursor";
import { HardBreak } from "@tiptap/extension-hard-break";
import { History as HistoryExt } from "@tiptap/extension-history";
import { Italic } from "@tiptap/extension-italic";
import { Link as LinkExt } from "@tiptap/extension-link";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph as paragraphExt } from "@tiptap/extension-paragraph";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Strike } from "@tiptap/extension-strike";
import { Subscript } from "@tiptap/extension-subscript";
import { Superscript } from "@tiptap/extension-superscript";
import { TableCell } from "@tiptap/extension-table-cell";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableRow } from "@tiptap/extension-table-row";
import { TaskItem } from "@tiptap/extension-task-item";
import { TaskList } from "@tiptap/extension-task-list";
import { Text } from "@tiptap/extension-text";
import SaveIcon from "@mui/icons-material/Save";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ShareIcon from "@mui/icons-material/ContentCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import "../App.css";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const CustomLinkExtension = LinkExt.extend({
	inclusive: false,
});

const CustomSubscript = Subscript.extend({
	excludes: "superscript",
});

const CustomSuperscript = Superscript.extend({
	excludes: "subscript",
});
export const History = () => {
	const { user } = React.useContext(AuthContext);
	const [rows, setRows] = useState([]);
	const [interaccionSelected, setInteraccionSelected] = useState(null);
	const [historial, setHistorial] = useState([]);
	const [mensajesGuardados, setMensajesGuardados] = useState("");
	const [rowSelected, setRowSelected] = useState([]);
	const rteRef = React.useRef(null);
	const editor = rteRef.current?.editor;
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [openTitle, setOpenTitle] = React.useState(false);
	const [title, setTitle] = React.useState("");
	const [openIa, setOpenIa] = React.useState(false);
	const [solicitaCambios, setSolicitaCambios] = React.useState("");
	const [mensajes, setMensajes] = React.useState([]);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [mensajeAlerta, setMensajeAlerta] = React.useState('');

	const handleClickAlert = (msg) => {
		setOpenAlert(true);
		setMensajeAlerta(msg);
	};

	const handleCloseAlert = () => {
		setOpenAlert(false);
	};

	const handleClickOpenIa = () => {
		setOpenIa(true);
	};

	const handleCloseIa = () => {
		setOpenIa(false);
	};

	const handleClickOpenTitle = () => {
		setOpenTitle(true);
	};

	const handleCloseTitle = () => {
		setOpenTitle(false);
	};

	const columns = [
		{ field: "id", headerName: "ID", minWidth: 50, flex: 1 },
		{ field: "asignatura", headerName: "Módulo", minWidth: 150, flex: 1 },
		{ field: "unidad", headerName: "Unidad", minWidth: 120, flex: 1 },
		{
			field: "aprendizaje",
			headerName: "Aprendizaje",
			minWidth: 250,
			flex: 1,
		},
		{
			field: "created_at",
			headerName: "Fecha",
			minWidth: 170,
			flex: 1,
			valueFormatter: (params) =>
				moment(params?.value).format("DD-MM-YYYY hh:mm A"),
		},
		{
			field: "prompt_title",
			headerName: "Pregunta",
			minWidth: 250,
			flex: 1,
		},
		{
			field: "total_historial",
			headerName: "Cambios",
			sortable: false,
			minWidth: 100,
			flex: 1,
		},
		{
			field: "action",
			headerName: "Respuesta",
			sortable: false,
			renderCell: ({ row }) => (
				<Button
					variant="contained"
					size="small"
					onClick={async () => {
						await getHistorial(row.id);
						setRowSelected(row);
						let firstPrompt = JSON.parse(row.pregunta);
						firstPrompt.splice(2)
						setMensajes(firstPrompt);
						console.log(firstPrompt);
						console.log(row);
						const currentSelection = editor.state.selection;
						editor
							.chain()
							.setContent(row.respuesta)
							.setTextSelection(currentSelection)
							.run();
						handleClickOpen();
					}}
				>
					Ver
				</Button>
			),
			minWidth: 100,
			flex: 1,
		},
	];

	const sendHistorial = async (tipo, mnsjes) => {
		let data = new FormData();
		data.append("bitacora_id", rowSelected.id);
		data.append("title", tipo === "Guardado" ? title : "");
		data.append("respuesta", editor.getHTML());
		data.append("pregunta", tipo === "Mejora" ? "solicitaCambios" : "");
		data.append("tipo", tipo);
		data.append("mensajes", tipo === "Guardado" ? mensajesGuardados : mnsjes );

		let config = {
			method: "post",
			url: `${process.env.REACT_APP_URL_APP}historial`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + user.authorization.token,
			},
			data: data,
		};

		const response = await axios.request(config);
		console.log(response.data);
		await getHistorial(rowSelected.id);
		handleClickAlert("Edición guardada con éxito!");
	};

	const getHistory = async () => {
		let config = {
			method: "get",
			maxBodyLength: Infinity,
			url: `${process.env.REACT_APP_URL_APP}bitacora/${user.user}`,
			headers: {
				Authorization: "Bearer " + user.authorization.token,
			},
		};

		const response = await axios.request(config);

		const responseHistory = response.data;
		const bitacora = responseHistory.bitacora;
		let finalHistory = [];
		for await (const result of bitacora) {
			const info = await getAprendizajes(result.aprendizaje_id);
			finalHistory.push({
				id: result.id,
				prompt_title: result.prompt_title,
				respuesta: result.respuesta,
				pregunta: result.pregunta,
				created_at: result.created_at,
				asignatura: info.asignatura,
				unidad: info.unidad,
				aprendizaje: info.aprendizaje,
				total_historial: result.total_historial,
				descr_corta: result.descr_corta,
				aprendizaje_id: result.aprendizaje_id,
				ejecucion_id: result.ejecucion_id,
			});
		}

		// console.log(finalHistory);

		setRows(finalHistory);
	};

	function exportHTML() {
		var preHtml =
			"<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
		var postHtml = "</body></html>";
		var html = preHtml + rteRef.current?.editor?.getHTML() + postHtml;

		var blob = new Blob(["\ufeff", html], {
			type: "application/msword",
		});

		// Specify link url
		var url =
			"data:application/vnd.ms-word;charset=utf-8," +
			encodeURIComponent(html);

		// Specify file name
		var filename = "document.doc";

		// Create download link element
		var downloadLink = document.createElement("a");

		document.body.appendChild(downloadLink);

		if (navigator.msSaveOrOpenBlob) {
			navigator.msSaveOrOpenBlob(blob, filename);
		} else {
			// Create a link to the file
			downloadLink.href = url;

			// Setting the file name
			downloadLink.download = filename;

			//triggering the function
			downloadLink.click();
		}

		document.body.removeChild(downloadLink);
	}

	const getAprendizajes = async (id) => {
		let data = JSON.stringify({
			aprendizaje_id: id,
		});

		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${process.env.REACT_APP_URL_APP}infoaprendizaje`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + user.authorization.token,
			},
			data: data,
		};

		const response = await axios.request(config);

		const responseInfo = response.data;

		return {
			asignatura: responseInfo.asignatura.namedescripcion,
			unidad: responseInfo.unidad.name,
			aprendizaje: responseInfo.aprendizaje.name,
		};
	};

	const getHistorial = async (id) => {
		let config = {
			method: "get",
			url: `${process.env.REACT_APP_URL_APP}historial/${id}`,
			headers: {
				Authorization: "Bearer " + user.authorization.token,
			},
		};

		const response = await axios.request(config);

		const responseInfo = response.data;
		setHistorial(responseInfo.bitacora);
		console.log(responseInfo.bitacora);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const saveToDoc = (text) => {
		let doc = new Document({
			sections: [
				{
					properties: {},
					headers: {
						default: new Header({
							children: [
								new Paragraph({
									text: "Asistente Instruccional | AIEP",
									heading: HeadingLevel.TITLE,
								}),
							],
						}),
					},
					children: [new Paragraph(text)],
				},
			],
		});

		Packer.toBlob(doc).then((blob) => {
			saveAs(blob, "respuesta.docx");
		});
	};

	const saveToPdf = (text) => {
		const doc = new jsPDF();
		doc.text(text, 10, 10, { maxWidth: 185, align: "justify" });
		doc.save("respuesta.pdf");
	};


	const getMessage = async () => {
		let newMensajes = [];
		newMensajes = [...mensajes];
		try {
			newMensajes.push({
				role: "user",
				content: "Haz los siguientes cambios: '" + solicitaCambios + "' sobre el siguiente contenido: " + rteRef.current?.editor?.getHTML() +
						". tu respuesta debe ser en html, usa almenos 2 espacios entre los titulos y parrafos y evita ```html"
			});

			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append(
				"Authorization",
				"Bearer " + user.authorization.token
			);

			var raw = JSON.stringify({
				mensajes: newMensajes,
				ejecucion_id: 0,
			});

			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: raw,
			};

			const response = await fetch(
				`${process.env.REACT_APP_URL_APP}message`,
				requestOptions
			);

			const reader = response.body.getReader();
			const decoder = new TextDecoder();

			let contenido = "";

			while (true) {
				const { value, done } = await reader.read();
				if (done) {
							
					break;
				}

				const decodedChunk = decoder.decode(value, { stream: true });
				contenido += decodedChunk;
				const currentSelection = editor.state.selection;
				editor
					.chain()
					.setContent(contenido)
					.setTextSelection(currentSelection)
					.run();
			}

			newMensajes.push({
				role: "assistant",
				content: contenido,
			});

			await sendHistorial("Mejora", JSON.stringify(newMensajes));
			handleClickAlert("Edición con IA guardada con éxito!");
		} catch (error) {
			console.log("error");
		}
	};

	useEffect(() => {
		getHistory();
	}, []);

	return (
		<Box>
			<Barra />
			<Box
				sx={{
					bgcolor: "background.paper",
					//   pt: 10,
					//   pb: 2,
					p: 10,
				}}
			>
				<Typography
					component="h3"
					variant="h4"
					align="center"
					color="text.primary"
					gutterBottom
				>
					Historial
				</Typography>

				<DataGrid
					getRowHeight={() => "auto"}
					localeText={
						esES.components.MuiDataGrid.defaultProps.localeText
					}
					density="compact"
					rows={rows}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 5 },
						},
					}}
					pageSizeOptions={[5, 10]}
				/>
			</Box>

			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				fullScreen
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				<AppBar sx={{ position: "relative", background: "#022746" }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={() => {
								handleClose();
								setInteraccionSelected(null);
							}}
						>
							<CloseIcon />
						</IconButton>
						<Typography
							sx={{ ml: 2, flex: 1 }}
							variant="h6"
							component="div"
						>
							Respuesta
						</Typography>
						{/* <IconButton
							color="inherit"
							onClick={() => {
								navigator.clipboard.writeText(
									rowSelected.respuesta
								);
							}}
							aria-label="close"
						>
							<ContentCopyIcon />
						</IconButton>
						<IconButton
							color="inherit"
							onClick={() => {
								saveToDoc(rowSelected.respuesta);
							}}
							aria-label="close"
						>
							<DescriptionIcon />
						</IconButton>
						<IconButton
							color="inherit"
							onClick={() => {
								saveToPdf(rowSelected.respuesta);
							}}
							aria-label="close"
						>
							<PictureAsPdfIcon />
						</IconButton> */}
					</Toolbar>
				</AppBar>
				<DialogContent>
					{/* <pre
            style={{
              margin: 10,
              fontSize: 12,
              fontWeight: 1,
              whiteSpace: "-moz-pre-wrap",
              whiteSpace: "-pre-wrap",
              whiteSpace: "-o-pre-wrap",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {rowSelected.respuesta}
          </pre> */}
					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={2}>
								<Stepper orientation="vertical">
									<Step active={true}>
										<StepLabel>Asignatura</StepLabel>
										<StepContent>
											<Typography>
												{rowSelected.asignatura}
											</Typography>
										</StepContent>
									</Step>
									<Step active={true}>
										<StepLabel>Unidad</StepLabel>
										<StepContent>
											<Typography>
												{rowSelected.unidad}
											</Typography>
										</StepContent>
									</Step>
									<Step active={true}>
										<StepLabel>Aprendizaje</StepLabel>
										<StepContent>
											<Typography>
												{rowSelected.aprendizaje}
											</Typography>
										</StepContent>
									</Step>
									<Step active={true}>
										<StepLabel>
											{rowSelected.ejecucion_id === 0 ? rowSelected.prompt_title : rowSelected.descr_corta}
										</StepLabel>
										<StepContent>
											<Typography>
												{rowSelected.ejecucion_id === 0
													? JSON.parse(
															rowSelected.pregunta
													  ).at(-1).content
													: rowSelected.prompt_title}
											</Typography>
										</StepContent>
									</Step>
								</Stepper>
							</Grid>
							<Grid item xs={10}>
								<div className="box-rt">
									<RichTextEditor
										ref={rteRef}
										extensions={[
											TableImproved.configure({
												resizable: true,
											}),
											TableRow,
											TableHeader,
											TableCell,

											BulletList,
											CodeBlock,
											documentoExt,
											HardBreak,
											ListItem,
											OrderedList,
											paragraphExt,
											CustomSubscript,
											CustomSuperscript,
											Text,

											// Blockquote must come after Bold, since we want the "Cmd+B" shortcut to
											// have lower precedence than the Blockquote "Cmd+Shift+B" shortcut. See
											// README
											Bold,
											Blockquote,

											Code,
											Italic,
											Strike,
											CustomLinkExtension.configure({
												autolink: true,
												linkOnPaste: true,
												openOnClick: false,
											}),
											LinkBubbleMenuHandler,

											// Extensions
											Gapcursor,
											HeadingWithAnchor.configure({
												// People shouldn't typically need more than 3 levels of headings, so
												// keep a more minimal set (than the default 6) to keep things simpler
												// and less chaotic.
												levels: [1, 2, 3],
											}),

											ResizableImage,

											// When images are dragged, we want to show the "drop cursor" for where they'll
											// land
											Dropcursor,

											TaskList,
											TaskItem.configure({
												nested: true,
											}),

											Placeholder.configure({
												placeholder:
													"Add your own content here...",
											}),

											// We use the regular `History` (undo/redo) extension when not using
											// collaborative editing
											HistoryExt,
										]} // Or any Tiptap extensions you wish!
										content={rowSelected.respuesta} // Initial content for the editor
										// Optionally include `renderControls` for a menu-bar atop the editor:
										renderControls={() => (
											<MenuControlsContainer>
												<MenuSelectHeading />

												<MenuDivider />

												<MenuButtonBold />
												<MenuButtonItalic />
												<MenuButtonStrikethrough />
												<MenuButtonSubscript />
												<MenuButtonSuperscript />

												<MenuDivider />

												{/* <MenuButtonEditLink /> */}

												<MenuDivider />

												<MenuButtonOrderedList />
												<MenuButtonBulletedList />
												<MenuButtonTaskList />

												<MenuDivider />

												<MenuButtonBlockquote />

												<MenuDivider />

												<MenuButtonCode />

												<MenuButtonCodeBlock />

												<MenuDivider />

												<MenuButtonAddTable />

												<MenuDivider />

												<MenuButtonRemoveFormatting />
												<MenuDivider />
												<IconButton
													sx={{
														cursor: "pointer",
													}}
													disabled={loading}
													onClick={async () => {
														navigator.clipboard.writeText(
															rteRef.current?.editor
																?.getHTML()
																.replace(
																	/<[^>]+>/g,
																	""
																)
														);
													}}
													title="Copiar HTML"
												>
													<ShareIcon />
												</IconButton>
												<IconButton
													disabled={loading}
													onClick={async () => {
														exportHTML();
													}}
													title="Descargar"
												>
													<FileDownloadIcon />
												</IconButton>
												<IconButton
													disabled={loading}
													onClick={handleClickOpenIa}
													color="primary"
													title="Mejor con IA"
												>
													<AutoFixHighIcon />
												</IconButton>
												<IconButton
													disabled={loading}
													onClick={async () => {
														handleClickOpenTitle();
													}}
													color="primary"
													title="Guardar"
												>
													<SaveIcon />
												</IconButton>
												<Autocomplete
													disablePortal
													options={historial}
													onClose={(value) => {
														console.log(value);
													}}
													value={interaccionSelected}
													getOptionLabel={(option) =>
														option &&
														option.tipo ==
															"Guardado"
															? option.title +
															  " - " +
															  new Date(
																	option.created_at
															  ).toLocaleString()
															: "Interacción con IA - " +
															  new Date(
																	option.created_at
															  ).toLocaleString()
													}
													onChange={(
														event,
														value
													) => {
														console.log(value);
														const currentSelection =
															editor.state
																.selection;
														if (value) {
															setInteraccionSelected(
																value
															);

															setMensajesGuardados(
																value.mensajes
															);
															let grupoMensajes = JSON.parse(value.mensajes)
															grupoMensajes.push({role: 'assistant', content: value.respuesta})
															
															setMensajes(grupoMensajes);

															editor
																.chain()
																.setContent(
																	value.respuesta
																)
																.setTextSelection(
																	currentSelection
																)
																.run();
														} else {
															setInteraccionSelected(
																null
															);
															editor
																.chain()
																.setContent(
																	rowSelected.respuesta
																)
																.setTextSelection(
																	currentSelection
																)
																.run();
														}
													}}
													sx={{ width: 300 }}
													size="small"
													noOptionsText="No hay interacciones"
													renderInput={(params) => (
														<TextField
															{...params}
															label="Interacciones"
														/>
													)}
												/>
											</MenuControlsContainer>
										)}
									/>
								</div>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				{/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions> */}
			</Dialog>

			<Dialog open={openTitle} onClose={handleCloseTitle}>
				<DialogTitle>Guardar cambios</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Guarda tu avance en la bitácora y continua trabajando
						despues.
					</DialogContentText>
					<TextField
						autoFocus
						required
						margin="dense"
						id="solicitaCambios"
						name="solicitaCambios"
						type="text"
						fullWidth
						variant="standard"
						onChange={(e) => {
							setTitle(e.target.value);
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseTitle}>Cancelar</Button>
					<Button
						onClick={() => {
							sendHistorial("Guardado");
							handleCloseTitle();
						}}
					>
						Guardar
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={openIa} onClose={handleCloseIa}>
				<DialogTitle>Solicitar cambios</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Solicita los cambios que necesitas en el contenido
						generado por el asistente.
					</DialogContentText>
					<TextField
						autoFocus
						required
						margin="dense"
						id="solicitaCambios"
						name="solicitaCambios"
						type="text"
						fullWidth
						variant="standard"
						onChange={(e) => {
							setSolicitaCambios(e.target.value);
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseIa}>Cancelar</Button>
					<Button
						onClick={() => {
							getMessage();
							handleCloseIa();
						}}
					>
						Enviar
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				open={openAlert}
				autoHideDuration={5000}
				onClose={handleCloseAlert}
			>
				<Alert
					onClose={handleCloseAlert}
					severity="success"
					variant="filled"
					sx={{ width: "100%" }}
				>
					{mensajeAlerta}
				</Alert>
			</Snackbar>
		</Box>
	);
};
